<template>
  <b-card>
    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
      <b-form @submit.prevent="handleSubmit(onSubmit)">
        <div class="d-flex">
          <feather-icon icon="FolderIcon" size="19"/>
          <h4 class="mb-0 ml-50">Form Bilgileri</h4>
        </div>

        <b-row class="mt-1">

          <b-col cols="12" md="6" lg="6">
            <validation-provider #default="validationContext" name="Form Tipi" rules="required">
              <b-form-group label="Form Tipi" :state="getValidationState(validationContext)">
                <v-select v-model="dataInfo.formTypeId" :options="formTypeOptions" :reduce="val => val.value" :clearable="false"/>
                <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="12" md="6" lg="6">
            <validation-provider #default="validationContext" name="Form Adı" rules="required">
              <b-form-group label="Form Adı">
                <b-form-input trim placeholder="Form Adı" v-model="dataInfo.name" :state="getValidationState(validationContext)"/>
                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="12" md="12" lg="12">
            <b-row>
              <b-col cols="12" md="6" lg="6">
                <validation-provider #default="validationContext" name="Açıklama">
                  <b-form-group label="Açıklama">
                    <b-form-textarea v-model="dataInfo.description" rows="8" placeholder="Açıklama..."/>
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12" md="6" lg="6">
                <b-row>
                  <b-col cols="12" md="12" lg="12">
                    <validation-provider #default="validationContext" name="e-Mail Adres">
                      <b-form-group label="e-Mail Adres" description="Bu form ile ilgili bir kayıt oluşturulduğunda belirtilen adrese mail gönderimi yapılır.">
                        <b-form-input trim placeholder="e-Mail Adres" v-model="dataInfo.emailAddress" :state="getValidationState(validationContext)"/>
                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <b-col cols="12" md="12" lg="12">
                    <validation-provider #default="validationContext" name="Geri Bildirim Gerektirir" rules="required">
                      <b-form-group label="Geri Bildirim Gerektirir" :state="getValidationState(validationContext)">
                        <v-select v-model="dataInfo.feedback" :options="yesNoOptions" :reduce="val => val.value" :clearable="false"/>
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <b-col cols="12" md="12" lg="12">
                    <validation-provider #default="validationContext" name="Durum" rules="required">
                      <b-form-group label="Durum" label-for="isActive" :state="getValidationState(validationContext)">
                        <v-select v-model="dataInfo.isActive" :options="statusOptions" :reduce="val => val.value" :clearable="false"/>
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-col>

          <b-col cols="12" md="12" lg="12">
            <validation-provider #default="validationContext" name="Erişim Bilgileri">
              <b-form-group label="Erişim Bilgileri" description="Bu forma ait form talep kayıtları ilgili erişim bilgisine sahip kullanıcılar tarafından görüntülenecektir." :state="getValidationState(validationContext)">
                <v-select v-model="dataInfo.accessDefinitionList" :options="accessDefinitionOptions" :reduce="val => val.value" :clearable="true" multiple="multiple"/>
                <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

        </b-row>

        <div class="d-flex">
          <feather-icon icon="ColumnsIcon" size="19"/>
          <h4 class="mb-0 ml-50">Soru Bilgileri</h4>
        </div>

        <div class="mt-1">
          <b-row class="border-bottom border-top pt-1">
            <b-col cols="12" md="4" lg="4">
              <b-form-group>
                <v-select v-model="questionGroupInfo.id" :options="formQuestionGroupOptions" :reduce="val => val.value" :clearable="false"/>
              </b-form-group>
            </b-col>
            <b-col cols="12" md="4" lg="4" class="pl-0">
              <b-button variant="primary" @click="newQuestionGroup">
                <feather-icon icon="PlusSquareIcon" size="16"/>
                <span class="align-middle" role="button"> Ekle</span>
              </b-button>
            </b-col>
          </b-row>

          <app-collapse accordion type="shadow" class="mt-1" v-if="dataInfo.questionGroups.length > 0">

            <app-collapse-item :title="group.questionGroupName" :sort-number="group.sortNumber" :is-visible="!group.isDeleted" v-for="(group,index) in dataInfo.questionGroups" :key="index" v-if="!group.isDeleted">

              <b-button variant="primary" @click="newQuestionModal(group)" class="mt-2 mr-1">
                <feather-icon icon="PlusSquareIcon" size="16"/>
                <span class="align-middle" role="button"> Yeni Soru Ekle</span>
              </b-button>

              <b-button variant="danger" @click="removeQuestionGroup(group)" class="mt-2">
                <feather-icon icon="XIcon" size="16"/>
                <span class="align-middle" role="button"> Soru Grubunu Çıkar</span>
              </b-button>

              <div class="pt-1">

                <div v-for="(question, questionIndex) in group.questions" :key="questionIndex" class="d-flex justify-content-between align-items-center border-top pt-1 pb-1" v-if="!question.isDeleted">
                  <div class="d-flex justify-content-between align-items-center">
                    <b-avatar size="32" class="mr-50" :text="question.sortNumber.toString()" :variant="`light-primary`"/>
                    <div class="user-page-info">
                      <h6 class="mb-0">
                        {{ question.name }}
                      </h6>
                      <small class="text-muted">{{ question.questionTypeId }}</small>
                    </div>
                  </div>
                  <div>
                    <b-button variant="warning" class="btn-icon" @click="editQuestion(group, question)">
                      <feather-icon icon="EditIcon"/>
                      Düzenle
                    </b-button>
                    <b-button variant="danger" class="btn-icon ml-1" @click="removeQuestion(group, question)">
                      <feather-icon icon="XIcon"/>
                      Çıkar
                    </b-button>
                  </div>
                </div>

              </div>

            </app-collapse-item>

          </app-collapse>
        </div>

        <b-modal v-model="modalShow" title="Soru Bilgileri" ok-title="Kaydet" cancel-title="Vazgeç" cancel-variant="outline-secondary" @show="resetModal" @hidden="resetModal" @ok="handleModal">
          <form ref="form" @submit.stop.prevent="submitModal">

            <b-form-group label="Sıra No">
              <b-form-input trim placeholder="Sıra No" v-model="questionInfo.sortNumber"/>
            </b-form-group>

            <b-form-group label="Soru">
              <b-form-input trim placeholder="Soru" v-model="questionInfo.name"/>
            </b-form-group>

            <b-form-group label="Soru Tipi">
              <v-select v-model="questionInfo.questionTypeId" :options="questionTypeOptions" :reduce="val => val.value" :clearable="false"/>
            </b-form-group>

            <b-form-group label="Açılır Liste Seçenekleri">
              <b-form-tags v-model="questionInfo.answerOptions" input-id="tags-basic" placeholder=""/>
            </b-form-group>

            <b-form-group label="Zorunlu">
              <v-select v-model="questionInfo.required" :options="yesNoOptions" :reduce="val => val.value" :clearable="false"/>
            </b-form-group>

            <b-form-group label="Açıklama">
              <b-form-input trim placeholder="Açıklama" v-model="questionInfo.description"/>
            </b-form-group>

            <b-form-group label="Durum">
              <v-select v-model="questionInfo.isActive" :options="statusOptions" :reduce="val => val.value" :clearable="false"/>
            </b-form-group>

          </form>
        </b-modal>

        <action-buttons :back-route="'definition-form-list'"/>

      </b-form>
    </validation-observer>
    <Overlay :busy="busy"></Overlay>
  </b-card>
</template>

<script>
import {BAvatar, BButton, BCard, BCol, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BFormTags, BFormTextarea, BModal, BRow, BTable} from 'bootstrap-vue'
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import {email, required} from '@validations'
import {onUnmounted, ref} from '@vue/composition-api'
import vSelect from 'vue-select'
import store from "@/store"
import storeModule from "@/views/definition/form/store"
import definitionModule from "@/views/system/definition/store"
import router from '@/router'
import {useToast} from 'vue-toastification/composition'
import {statusOptions, yesNoOptions} from "@core/utils/filter"
import Overlay from "@/components/Overlay.vue";
import ActionButtons from "@/components/Form/ActionButtons.vue";
import {toastMessage} from "@core/utils/utils";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import Vue from "vue";
import accessModule from "@/views/definition/access/store"

export default {
  components: {
    BFormTags,
    BAvatar,
    BModal,
    BTable,
    BFormTextarea,
    BCard,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BFormInvalidFeedback,

    AppCollapseItem,
    AppCollapse,
    ActionButtons,
    Overlay,
    vSelect,

    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      required,
      email,
    }
  },
  setup() {
    const STORE_MODULE_NAME = 'store'
    const STORE_DEFINITION_MODULE_NAME = 'store-definition'
    const STORE_ACCESS_MODULE_NAME = 'store-access'

    if (!store.hasModule(STORE_MODULE_NAME)) {
      store.registerModule(STORE_MODULE_NAME, storeModule)
      store.registerModule(STORE_DEFINITION_MODULE_NAME, definitionModule)
      store.registerModule(STORE_ACCESS_MODULE_NAME, accessModule)
    }
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) {
        store.unregisterModule(STORE_MODULE_NAME)
        store.unregisterModule(STORE_DEFINITION_MODULE_NAME)
        store.unregisterModule(STORE_ACCESS_MODULE_NAME)
      }
    })

    const toast = useToast()
    const busy = ref(false)

    const questionGroupInfo = ref({id: '0'})
    const dataInfo = ref({
      id: null,
      formTypeId: null,
      name: '',
      emailAddress: '',
      description: '',
      isActive: true,
      feedback: false,
      questionGroups: [],
      accessDefinitionList: []
    })

    const questionInfo = ref({
      id: null,
      sortNumber: 0,
      name: '',
      questionTypeId: null,
      answerOptions: [],
      description: '',
      required: false,
      isActive: true,
      isDeleted: false,
    })

    const blankQuestionInfo = ref({
      id: null,
      sortNumber: 0,
      name: '',
      questionTypeId: null,
      answerOptions: [],
      description: '',
      required: false,
      isActive: true,
      isDeleted: false,
    })

    const modalShow = ref(false)
    const questionGroup = ref(null)
    const question = ref(null)

    busy.value = true
    const formTypeOptions = ref([])
    store.dispatch('store-definition/definitionList', {groupKey: 'FORM_TYPE'}).then(response => {
      response.data.data.forEach((value, index) => {
        formTypeOptions.value.push({label: value.name, value: value.key})
        if (value.isDefault && router.currentRoute.params.id === undefined) {
          dataInfo.value.formTypeId = value.key
        }
      });
    }).catch(error => {
      toastMessage(toast, 'danger', error.response.data.message)
    }).finally(message => {
      busy.value = false
    })

    busy.value = true
    const formQuestionGroupOptions = ref([])
    formQuestionGroupOptions.value.push({label: 'Yeni soru grubu ekleyiniz...', value: '0'})
    store.dispatch('store-definition/definitionList', {groupKey: 'FORM_QUESTION_GROUP'}).then(response => {
      response.data.data.forEach((value, index) => {
        formQuestionGroupOptions.value.push({label: value.name, value: value.key})
      });
    }).catch(error => {
      toastMessage(toast, 'danger', error.response.data.message)
    }).finally(message => {
      busy.value = false
    })

    busy.value = true
    const questionTypeOptions = ref([])
    store.dispatch('store-definition/definitionList', {groupKey: 'FORM_QUESTION_TYPE'}).then(response => {
      response.data.data.forEach((value, index) => {
        questionTypeOptions.value.push({label: value.name, value: value.key})
      });
    }).catch(error => {
      toastMessage(toast, 'danger', error.response.data.message)
    }).finally(message => {
      busy.value = false
    })

    if (router.currentRoute.params.id > 0) {
      busy.value = true
      store.dispatch('store/fetchItem', {id: router.currentRoute.params.id}).then(response => {
        dataInfo.value = response.data.data
      }).catch(error => {
        toastMessage(toast, 'danger', error.response.data.message)
        if (error.response.status === 403) {
          router.push({name: 'definition-form-list'})
        }
      }).finally(message => {
        busy.value = false
      })
    }

    const onSubmit = () => {
      busy.value = true
      store.dispatch('store/' + (router.currentRoute.params.id > 0 ? 'editItem' : 'addItem'), dataInfo.value).then(response => {
        toastMessage(toast, 'success', response.data.message)
        router.push({name: 'definition-form-list'})
      }).catch(error => {
        toastMessage(toast, 'danger', error.response.data.message)
      }).finally(message => {
        busy.value = false
      })
    }

    const newQuestionGroup = () => {
      if (questionGroupInfo.value.id !== '0') {
        let groupName = '';
        formQuestionGroupOptions.value.forEach((value, index) => {
          if (value.value === questionGroupInfo.value.id) {
            groupName = value.label;
          }
        });
        dataInfo.value.questionGroups.push({
          id: null,
          questionGroupId: questionGroupInfo.value.id,
          questionGroupName: groupName,
          sortNumber: dataInfo.value.questionGroups.length + 1,
          isActive: true,
          isDeleted: false,
          questions: [],
        })
        questionGroupInfo.value.id = '0';
      } else {
        Vue.swal({
          icon: 'error',
          title: 'Hata!',
          text: 'Lütfen soru grubu seçimi yapınız!',
          confirmButtonText: 'Tamam',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        })
      }
    }

    const newQuestionModal = (questionGroupData) => {
      questionGroup.value = questionGroupData
      questionInfo.value = blankQuestionInfo.value;
      modalShow.value = true
    }

    const resetModal = () => {
      //questionInfo.value = blankQuestionInfo.value;
    }

    const submitModal = () => {
      dataInfo.value.questionGroups.forEach((value, index) => {
        if (value === questionGroup.value) {
          if (question.value === null) {
            value.questions.push({
              id: questionInfo.value.id,
              sortNumber: questionInfo.value.sortNumber,
              name: questionInfo.value.name,
              questionTypeId: questionInfo.value.questionTypeId,
              answerOptions: questionInfo.value.answerOptions,
              description: questionInfo.value.description,
              required: questionInfo.value.required,
              isActive: questionInfo.value.isActive,
              isDeleted: questionInfo.value.isDeleted,
            })
          } else {
            value.questions.forEach((valueQuestion, indexQuestion) => {
              if (valueQuestion === question.value) {
                valueQuestion.id = questionInfo.value.id
                valueQuestion.sortNumber = questionInfo.value.sortNumber
                valueQuestion.name = questionInfo.value.name
                valueQuestion.questionTypeId = questionInfo.value.questionTypeId
                valueQuestion.answerOptions = questionInfo.value.answerOptions
                valueQuestion.description = questionInfo.value.description
                valueQuestion.required = questionInfo.value.required
                valueQuestion.isActive = questionInfo.value.isActive
                valueQuestion.isDeleted = questionInfo.value.isDeleted
              }
            })
          }
        }
      });

      questionInfo.value = blankQuestionInfo.value;
      modalShow.value = false

      questionGroup.value = null
      question.value = null
    }

    const handleModal = (bvModalEvt) => {
      bvModalEvt.preventDefault()
      submitModal()
    }

    const removeQuestionGroup = (questionGroupData) => {
      Vue.swal({
        title: 'Onaylıyor musunuz?',
        text: 'Kayıt çıkarılacaktır!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Evet, Çıkar',
        cancelButtonText: 'Vazgeç',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          dataInfo.value.questionGroups.forEach((value, index) => {
            if (value === questionGroupData) {
              questionGroupInfo.value.id = '1'
              value.isDeleted = true
              questionGroupInfo.value.id = '0'
            }
          });
        }
      })
    }

    const removeQuestion = (questionGroupData, questionData) => {
      Vue.swal({
        title: 'Onaylıyor musunuz?',
        text: 'Kayıt çıkarılacaktır!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Evet, Çıkar',
        cancelButtonText: 'Vazgeç',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          dataInfo.value.questionGroups.forEach((value, index) => {
            if (value === questionGroupData) {
              value.questions.forEach((valueQuestion, indexQuestion) => {
                if (valueQuestion === questionData) {
                  valueQuestion.isDeleted = true
                }
              })
            }
          })
        }
      })
    }

    const editQuestion = (questionGroupData, questionData) => {
      questionGroup.value = questionGroupData
      question.value = questionData

      questionInfo.value.id = questionData.id
      questionInfo.value.sortNumber = questionData.sortNumber
      questionInfo.value.name = questionData.name
      questionInfo.value.questionTypeId = questionData.questionTypeId
      questionInfo.value.answerOptions = questionData.answerOptions
      questionInfo.value.description = questionData.description
      questionInfo.value.required = questionData.required
      questionInfo.value.isActive = questionData.isActive
      questionInfo.value.isDeleted = questionData.isDeleted

      modalShow.value = true
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation()

    const accessDefinitionOptions = ref([])
    busy.value = true
    store.dispatch('store-access/fetchItems').then(response => {
      response.data.data.forEach((value, index) => {
        if (value.isActive) {
          accessDefinitionOptions.value.push({label: value.name, value: value.id})
        }
      });
    }).catch(error => {
      toastMessage(toast, 'danger', error.response.data.message)
    }).finally(message => {
      busy.value = false
    })

    return {
      busy,
      dataInfo,
      refFormObserver,
      formTypeOptions,
      formQuestionGroupOptions,
      questionTypeOptions,
      questionGroupInfo,
      questionInfo,
      modalShow,
      accessDefinitionOptions,

      statusOptions,
      yesNoOptions,

      onSubmit,
      getValidationState,
      resetForm,
      newQuestionGroup,
      resetModal,
      submitModal,
      handleModal,
      newQuestionModal,
      removeQuestionGroup,
      removeQuestion,
      editQuestion,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
